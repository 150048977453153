<script>
import FullCalendar from "@fullcalendar/vue";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import bootstrapPlugin from "@fullcalendar/bootstrap";
import listPlugin from "@fullcalendar/list";

import Swal from "sweetalert2";
import { required } from "vuelidate/lib/validators";

import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";

import { categories } from "./data-calendar";
import {
  ClassesDaysMethods,
  SessionsDatesMethods,
  studentClassMethods,
} from "@/state/helpers";

const calendarEvents = [];
/**
 * Calendar component
 */
export default {
  page: {
    title: "Calendar",
    meta: [{ name: "description" }],
  },
  components: { FullCalendar, Layout, PageHeader },
  data() {
    return {
      Student: null,
      title: "Calendar",
      items: [
        {
          text: "NGS",
        },
        {
          text: "Calendar",
          active: true,
        },
      ],
      calendarEvents: calendarEvents,
      calendarOptions: {
        height: 650,
        headerToolbar: {
          left: "prev,next today",
          center: "title",
          right: "timeGridDay",
        },
        plugins: [
          dayGridPlugin,
          timeGridPlugin,
          interactionPlugin,
          bootstrapPlugin,
          listPlugin,
        ],
        initialView: "timeGridDay",
        themeSystem: "bootstrap",
        initialEvents: calendarEvents,
        editable: false,
        droppable: false,
        eventResizableFromStart: true,
        dateClick: this.dateClicked,
        eventClick: this.editEvent,
        eventsSet: this.handleEvents,
        weekends: true,
        selectable: true,
        selectMirror: true,
        dayMaxEvents: true,
      },
      currentEvents: [],
      showModal: false,
      eventModal: false,
      categories: categories,
      submitted: false,
      submit: false,
      newEventData: {},
      edit: {},
      deleteId: {},
      event: {
        title: "",
        category: "",
      },
      editevent: {
        editTitle: "",
        editcategory: "",
      },
      // added here
      CalendarData: [],
    };
  },
  validations: {
    event: {
      title: { required },
      category: { required },
    },
  },
  created() {
    this.Student = JSON.parse(localStorage.getItem("user"));
    this.getStudentClass({ id: this.Student.id });
   // calendar.setOption('height', 700);
    this.initData();
  },
  computed: {
    sessionsdates() {
      return this.$store ? this.$store.state.sessionsdates.SessionsDates : null;
    },
    classesdays() {
      return this.$store ? this.$store.state.classesdays.ClassesDays : null;
    },
    studentsclass() {
      return this.$store ? this.$store.state.studentsclass.StudentsClass : null;
    },
  },
  methods: {
    ...studentClassMethods,
    ...ClassesDaysMethods,
    ...SessionsDatesMethods,
    initData() {
      let dataCal = [];
      let ind = 1;
      this.classesdays.forEach((el) => {
        if(this.checkClasses(el.classid)){
        let sess = this.sessionsdates.filter(
          (res) => res.classid == el.classid
        );
        sess.forEach((se) => {
          let dido = new Date(
            se.date.split("/")[2] +
              "/" +
              se.date.split("/")[1] +
              "/" +
              se.date.split("/")[0] +
              " " +
              el.startat
          );
          let TheDay = dido.getDay() + 1;
          if (TheDay == 7) {
            TheDay = 0;
          }
          if (TheDay == el.day) {
            let obj = {
              id: ind,
              title: el.title,
              start: new Date(
                se.date.split("/")[2] +
                  "/" +
                  se.date.split("/")[1] +
                  "/" +
                  se.date.split("/")[0] +
                  " " +
                  el.startat
              ),
              end: new Date(
                se.date.split("/")[2] +
                  "/" +
                  se.date.split("/")[1] +
                  "/" +
                  se.date.split("/")[0] +
                  " " +
                  el.endat
              ),
              className: "bg-primary text-white",
            };
            dataCal.push(obj);
            ind++;
          
          }
        });
      }
      });
      this.CaledarData = dataCal;
      this.calendarEvents = this.CaledarData;
      this.calendarOptions.initialEvents = this.CaledarData;
    },
    checkClasses(val) {
      let sub = this.studentsclass.filter(x=>x.classid == val)
      if(sub.length>0){
        return true
      }else{
        return false
      }
    },
    /**
     * Modal form submit
     */
    // eslint-disable-next-line no-unused-vars
    handleSubmit(e) {
      this.submitted = true;

      // stop here if form is invalid
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      } else {
        const title = this.event.title;
        const category = this.event.category;
        let calendarApi = this.newEventData.view.calendar;

        this.currentEvents = calendarApi.addEvent({
          id: this.newEventData.length + 1,
          title,
          start: this.newEventData.date,
          end: this.newEventData.date,
          classNames: [category],
        });
        this.successmsg();
        this.showModal = false;
        this.newEventData = {};
      }
      this.submitted = false;
      this.event = {};
    },
    // eslint-disable-next-line no-unused-vars
    hideModal(e) {
      this.submitted = false;
      this.showModal = false;
      this.event = {};
    },
    /**
     * Edit event modal submit
     */
    // eslint-disable-next-line no-unused-vars
    editSubmit(e) {
      this.submit = true;
      const editTitle = this.editevent.editTitle;
      const editcategory = this.editevent.editcategory;

      this.edit.setProp("title", editTitle);
      this.edit.setProp("classNames", editcategory);
      this.successmsg();
      this.eventModal = false;
    },

    /**
     * Delete event
     */
    deleteEvent() {
      this.edit.remove();
      this.eventModal = false;
    },
    /**
     * Modal open for add event
     */
    dateClicked(info) {
      this.newEventData = info;
      this.showModal = true;
    },
    /**
     * Modal open for edit event
     */
    editEvent(info) {
      this.edit = info.event;
      this.editevent.editTitle = this.edit.title;
      this.editevent.start = this.edit.start;
      this.editevent.end = this.edit.end;
      this.editevent.editcategory = this.edit.classNames[0];
      this.eventModal = true;
    },

    closeModal() {
      this.eventModal = false;
    },

    confirm() {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to delete this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.value) {
          this.deleteEvent();
          Swal.fire("Deleted!", "Event has been deleted.", "success");
        }
      });
    },

    /**
     * Show list of events
     */
    handleEvents(events) {
      this.currentEvents = events;
    },

    /**
     * Show successfull Save Dialog
     */
    successmsg() {
      Swal.fire({
        position: "center",
        icon: "success",
        title: "Event has been saved",
        showConfirmButton: false,
        timer: 1000,
      });
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader title="Calendrier" />
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="app-calendar" >
              <FullCalendar
                
                ref="fullCalendar"
                :options="calendarOptions"
              ></FullCalendar>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-modal
      v-model="showModal"
      title="Empty"
      title-class="text-black font-18"
      body-class="p-3"
      hide-footer
    >
    </b-modal>

    <!-- Edit Modal -->
    <b-modal
      v-model="eventModal"
      title="Details"
      title-class="text-black font-18"
      hide-footer
      body-class="p-0"
    >
      <form>
        <div class="p-3">
          <label>{{ editevent.editTitle }}</label>
        </div>
        <div class="text-right p-3">
          <label v-if="editevent.start != null && editevent.end != null"
            >{{ editevent.start.getDate() }}/{{ editevent.start.getMonth() }}/{{
              editevent.start.getFullYear()
            }}
            | {{ editevent.start.getHours() }}:{{
              editevent.start.getMinutes()
            }}
            - {{ editevent.end.getHours() }}:{{
              editevent.end.getMinutes()
            }}</label
          >
        </div>
      </form>
    </b-modal>
  </Layout>
</template>
